(function($) {
  $(window).on('load', function() {
    $.instagramFeed({
      username: 'zammersorvetes',
      container: '#instafeed',
      display_profile: false,
      display_biography: false,
      display_gallery: true,
      callback: null,
      styling: true,
      items: 6,
      items_per_row: 3,
      margin: 0.25,
    });
  });
})(jQuery);
