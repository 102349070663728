import 'magnific-popup/dist/jquery.magnific-popup';
import 'jquery.maskedinput/src/jquery.maskedinput';

(function($) {
  $(document).ready(function() {
    $('.navbar-nav>li>a').on('click', function() {
      $('.navbar-collapse').collapse('hide');
    });

    $('#phone')
      .mask('(99) 9999-9999?9')
      .focusout(function(event) {
        var target, phone, element;
        target = event.currentTarget ? event.currentTarget : event.srcElement;
        phone = target.value.replace(/\D/g, '');
        element = $(target);
        element.unmask();
        if (phone.length > 10) {
          element.mask('(99) 99999-999?9');
        } else {
          element.mask('(99) 9999-9999?9');
        }
      });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });

    $('.ms_products_inner').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Carregando imagem #%curr%...',
      tClose: 'Fechar (Esc)', // Alt text on close button
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        tPrev: 'Anterior (Botão seta esquerda)', // Alt text on left arrow
        tNext: 'Próximo (Botão seta direita)', // Alt text on right arrow
        tCounter: '<span class = "mfp-counter">%curr% de %total%</span>', // markup of counter
      },
      image: {
        tError: '<a href = "%url%">A imagem #%curr%</a> não foi carregada = (',
        titleSrc: function() {
          return '<small>por Zammer Sorvetes</small>';
          // return item.el.attr('title') + '<small>por Zammer Sorvetes</small>';
        },
      },
    });
  });
})(jQuery);
