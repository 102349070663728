// from node_modules
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/scrollspy';

// styles
import '../scss/index.scss';

// custom scripts
import './nav';
import './form';
import './isotope';
import './jquery.instagramFeed.min';
import './jquery.ready';
import './jquery.load';
