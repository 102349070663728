import 'jquery-validation/dist/jquery.validate.js';

(function($) {
  function form_validation() {
    var $contactForm = $('#contactForm');
    var $btnSubmit = $('#btnSubmit');

    $('form input, form textarea').on('blur keyup', function() {
      if ($contactForm.valid()) {
        $btnSubmit.prop('disabled', false);
      } else {
        $btnSubmit.prop('disabled', 'disabled');
      }
    });

    $contactForm.submit(function(event) {
      event.preventDefault();

      $.ajax({
        url: 'https://api.staticforms.xyz/submit',
        type: 'POST',
        dataType: 'json',
        data: $contactForm.serialize(),
      }).done(function(data) {
        if (data.success) {
          $contactForm[0].reset();
          var thankYouMessage = document.querySelector('.thankyou_message');

          if (thankYouMessage) {
            thankYouMessage.style.display = 'block';
          }
        }
      });
    });

    jQuery.validator.addMethod(
      'answercheck',
      function(value, element) {
        return this.optional(element) || /^\bcat\b$/.test(value);
      },
      'escreva a resposta correta -_-'
    );

    $contactForm.validate({
      rules: {
        name: {
          required: true,
          minlength: 3,
        },
        email: {
          required: true,
          email: true,
        },
        tel: {
          required: true,
          minlength: 4,
        },
        message: {
          required: true,
          minlength: 20,
        },
      },
      messages: {
        name: {
          required: 'Vamos lá, você tem um nome, não tem?',
          minlength: 'Seu nome deve ter mais do que 2 letras',
        },
        email: {
          required: 'Sem e-mail não temos como te responder',
          email: 'Por favor insira um e-mail válido',
        },
        tel: {
          required:
            'Nos informe seu telefone, assim poderemos entrar em contato mais rápido',
          minlength:
            'Você deve preencher colocando o seu DDD e em seguida seu número de telefone. Ex: (XX) XXXX-XXXX',
        },
        message: {
          required:
            'Hummm, você precisa escrever alguma coisa para enviar esse formulário',
          minlength:
            'Apenas isso? Tem certeza que não quer nos contar mais nada?',
        },
      },
    });
  }
  form_validation();
})(jQuery);
