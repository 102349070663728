(function($) {
  //* Navbar Fixed
  function navbarFixed() {
    var nav_offset_top = $('header').height();
    var $mainMenu = $('.main_menu_area');
    var $window = $(window);

    if ($mainMenu.length) {
      $window.scroll(function() {
        var scroll = $window.scrollTop();
        if (scroll >= nav_offset_top) {
          $mainMenu.addClass('navbar_fixed');
        } else {
          $mainMenu.removeClass('navbar_fixed');
        }
      });
    }
  }
  navbarFixed();

  function scrollNav() {
    var offset = 80;
    var $htmlBody = $('html, body');
    // Select all links with hashes
    $('a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, '') ==
            this.pathname.replace(/^\//, '') &&
          location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length
            ? target
            : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $htmlBody.animate(
              {
                scrollTop: target.offset().top - offset,
              },
              1000,
              function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(':focus')) {
                  // Checking if the target was focused
                  return false;
                } else {
                  $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                }
              }
            );
          }
        }
      });
  }
  scrollNav();
})(jQuery);
